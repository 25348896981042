<template>
    <div class="vm-section vm-fibre-process">
        <v-container class="text-center">
            <h2>The Fibre Process</h2>
            <v-row class="vm-process-blocks">
                <v-col class="col-6 col-sm-4 col-lg-5ths">
                    <div class="vm-b-img">
                        <img src="@/assets/images/products/core/vuma-fibre-process-1.svg" alt="Vuma Fibre Process">
                    </div>
                    <h4>Planning for more</h4>
                    <p>We plan and design the fibre network for your suburb.</p>
                    <!-- <v-btn href="#" text class="mt-0">
                      > Read more
                    </v-btn> -->
                </v-col>
                <v-col class="col-6 col-sm-4 col-lg-5ths">
                    <div class="vm-b-img">
                        <img src="@/assets/images/products/core/vuma-fibre-process-2.svg" alt="Vuma Fibre Process">
                    </div>
                    <h4>Informing you and your neighbourhood</h4>
                    <p>We inform you that your fibre roll-out will start soon.</p>
                    <!-- <v-btn href="#" text class="mt-0">
                      > Read more
                    </v-btn> -->
                </v-col>
                <v-col class="col-6 col-sm-4 col-lg-5ths">
                    <div class="vm-b-img">
                        <img src="@/assets/images/products/core/vuma-fibre-process-3.svg" alt="Vuma Fibre Process">
                    </div>
                    <h4>Building a network of connections</h4>
                    <p>We roll-out the planned fibre network for your area.</p>
                    <!-- <v-btn href="#" text class="mt-0">
                      > Read more
                    </v-btn> -->
                </v-col>
                <v-col class="col-6 col-sm-4 col-lg-5ths">
                    <div class="vm-b-img">
                        <img src="@/assets/images/products/core/vuma-fibre-process-4.svg" alt="Vuma Fibre Process">
                    </div>
                    <h4>Restoration</h4>
                    <p>We test, and ensure that the area is restored to its original state.</p>
                    <!-- <v-btn href="#" text class="mt-0">
                      > Read more
                    </v-btn> -->
                </v-col>
                <v-col class="col-6 col-sm-4 col-lg-5ths">
                    <div class="vm-b-img">
                        <img src="@/assets/images/products/core/vuma-fibre-process-5.svg" alt="Vuma Fibre Process">
                    </div>
                    <h4>Install and connect to more</h4>
                    <p>Order your fibre service from your preferred Internet Service Provider.</p>
                    <!-- <v-btn href="#" text class="mt-0">
                      > Read more
                    </v-btn> -->
                </v-col>
            </v-row>

            <v-btn to="/omniscript" target="_blank" rounded outlined v-if="searched">
                See available packages
            </v-btn>
            <v-btn to="/" data-section="vm-coverage-wrap" rounded outlined class="move-to" v-else>
              Check my coverage
            </v-btn>

        </v-container>
    </div>
</template>

<script>
  export default {
    name: 'CoreFibreProcess',
    data: () => ({
        searched: false,
    }),
    mounted(){
        $("html").css("overflow", "auto");
        $('#browse-shop-top').removeClass('d-none');

        if ($cookies.isKey("user_address")) {
            this.searched = true;
        }

        $('.move-to').on('click', function (e) {
            e.preventDefault();

            // var target = this.hash;
            var target = '#' + $(this).data('section');
            var $target = $(target);

            $('html, body').stop().animate({
                'scrollTop': $target.offset().top - 70
            }, 900, 'swing');
        });
    }
  }
</script>

<style lang="scss">
    .vm-fibre-process{
        background-color: #EE008C;
        color: #fff;
        &.vm-section{
            padding-top: 5rem;
            padding-bottom: 5rem;
        }
        // NEST
        // .container{ max-width: 1700px; }
        h2{
            margin-bottom: 3rem;
        }
        h4{
            font-size: 22px;
            line-height: 1.2;
            margin-bottom: 1rem;
            font-family: "Core Sans R 55", "Roboto", sans-serif !important;
            font-weight: inherit;
        }
        .vm-b-img{
            width: 150px;
            margin: auto;
            margin-bottom: 1.5rem;
        }
        p{
            line-height: 1.2;
            padding: 0 1rem;
            margin-bottom: 0;
        }
        .theme--light.v-btn {
            color: #fff;
            margin-top: 3rem;
            &:before{ opacity: 0; }
            &:hover{
                background-color: #fff;
                color: #ff008e;
            }
        }
        .vm-process-blocks{
            // NEST
            .v-btn{ font-size: 18px !important; }
        }
    }
</style>