<template>
    <div class="vm-section vm-core d-flex align-center">
        <v-container class="text-center">
            <v-row class="justify-center">
                <v-col class="col-12 col-md-7">
                    <h1>What is Vuma Fibre Core?</h1>
                    <p>
                        <strong>It’s a strong fibre network, a service that you are familiar with, enabling connectivity and helps you create a connected lifestyle.</strong>
                        <br><br>
                        With internet speeds of up to 1 gigabit per second, Vuma Fibre Core gives you and your family the choice to select what you need based on what is important to you.
                        <br><br>
                        <strong>How?</strong> Fibre cables are run past your home. These cables connect you, through an Internet Service Provider, to world-class internet.
                    </p>
                    <v-btn to="/omniscript" target="_blank" rounded outlined class="pink-outline" v-if="searched">
                      See available packages
                    </v-btn>
                    <v-btn to="/" data-section="vm-coverage-wrap" rounded outlined class="pink-outline move-to" v-else>
                      Check my coverage
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
  export default {
    name: 'ProductCore',
    data: () => ({
        searched: false,
    }),
    mounted(){
        $("html").css("overflow", "auto");
        $('#browse-shop-top').removeClass('d-none');

        if ($cookies.isKey("user_address")) {
            this.searched = true;
        }

        $('.move-to').on('click', function (e) {
            e.preventDefault();

            // var target = this.hash;
            var target = '#' + $(this).data('section');
            var $target = $(target);

            $('html, body').stop().animate({
                'scrollTop': $target.offset().top - 70
            }, 900, 'swing');
        });
    }
  }
</script>

<style lang="scss">
    .vm-core{
        position: relative;
        height: 700px;
        // NEST
        h2, p{ margin-bottom: 2rem !important; }
        h1{
            font-size: 64px;
            font-family: "Core Sans R 25", "Roboto", sans-serif !important;
            font-weight: inherit;
            margin-bottom: 2rem !important;
        }

        
    }
</style>