<template>
    <div class="vm-banner vm-product-banner d-flex align-center">
        <div class="swiper-container" id="lines-swiper">
            <div class="swiper-wrapper">
                <div class="swiper-slide d-flex align-center" v-for="(item,i) in slides" :key="i">
                    <div class="vm-banner-bg" :style="{ backgroundImage: 'url(' + require('@/assets/images/products/core/'+ item.src) + ')' }">
                        <img src="@/assets/images/products/core/vuma-core-banner.jpg" alt="">
                    </div>
                    <v-container>
                        <div class="header-wrap">
                            <!-- <div class="small-header">> Fibre to the home</div> -->
                            <div class="bigger-header" v-html="item.title"></div>
                            <div class="sub-header">{{ item.subHeader }}</div>    
                        </div>

                        <v-btn to="/omniscript" target="_blank" rounded outlined dark v-if="searched">
                          See available packages
                        </v-btn>

                        <v-btn to="/" data-section="vm-coverage-wrap" rounded outlined dark class="move-to" v-else>
                          Check my coverage
                        </v-btn>

                    </v-container>
                </div>
            </div>
            <!-- <div class="swiper-button-next" id="lines-next"></div>
            <div class="swiper-button-prev" id="lines-prev"></div> -->
            <div class="swiper-pagination"></div>
        </div>
    </div>
</template>

<script>
  import Swiper from "swiper";
  import "swiper/css/swiper.min.css";

  export default {
    data () {
      return {
        dialog: false,
        slides: [
          {
            title: 'Create the connected <br><strong>life you want.</strong>',
            subHeader: 'With a strong fibre network, one that\'s core to supporting your day-to-day needs.',
            src: 'banners/core-banner-1.jpg'
          },
          {
            title: 'Choose to be connected.<br><strong>Choose more.</strong>',
            subHeader: 'With fast and uninterrupted internet line speeds of up to 1 gigabit per second.',
            src: 'banners/core-banner-2.jpg'
          },
          {
            title: 'Do More. See More.<br><strong>Dream More.</strong>',
            subHeader: 'Experience more from the comfort of your home.',
            src: 'vuma-core-banner.jpg'
          }
        ],
        searched: false,
      }
    },
    mounted(){
        var mySwiper = new Swiper('#lines-swiper', {
            preloadImages: true,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            paginationClickable: true,
            loop: true,
            speed: 600,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false
            },
            centeredSlides: true,
            spaceBetween: 0
        });

        if ($cookies.isKey("user_address")) {
            this.searched = true;
        }

        $('.move-to').on('click', function (e) {
            e.preventDefault();

            // var target = this.hash;
            var target = '#' + $(this).data('section');
            var $target = $(target);

            $('html, body').stop().animate({
                'scrollTop': $target.offset().top - 70
            }, 900, 'swing');
        });
    }
  }
</script>

<style lang="scss">
  .vm-product-banner{
    // NEST
    .container .header-wrap .sub-header{
      max-width: 750px;
    }
  }
</style>