<template>
<div id="high-speed">
  <v-card class="vm-tabs-product">
    <v-tabs
      v-model="tab"
      dark
      grow
    >
      <v-tab key="tab-core-entertain">Entertainment</v-tab>
      <v-tab key="tab-core-connect">Connect</v-tab>
      <v-tab key="tab-core-choose">Safety</v-tab>
      <v-tab key="tab-core-work">Work</v-tab>
      <v-tab key="tab-core-learn">Learn</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" class="vm-core-tabs">
		<v-tab-item key="tab-core-entertain">
			<div class="vm-section vm-tab-items d-flex align-center">
				<v-container>
					<v-row>
						<v-col class="col-12 col-sm-5 col-md-5">
							<h2 class="text-white">Stream your way to online entertainment</h2>
							<p class="vm-tab-content text-white" style="width: 100%; max-width: 460px; ">
								Unlock a new world of entertainment and download new content, in real-time. Stream your favourite online series or movies, participate in an online exercise class or game to your heart’s content. Enjoy more entertainment from the comfort of your home.
							</p>
							<v-btn to="/omniscript" target="_blank" rounded outlined v-if="searched" class="btn-white">
								See available packages
							</v-btn>
									<v-btn to="/" data-section="vm-coverage-wrap" rounded outlined class="move-to btn-white" v-else>
		                      Check my coverage
		                    </v-btn>
						</v-col>
					</v-row>
				</v-container>
				<div class="vm-tab-bg" :style="{ backgroundImage: 'url(' + require('@/assets/images/products/core/tabs/core-entertainment.jpg') + ')' }">
					<img src="@/assets/images/products/core/tabs/core-entertainment.jpg" alt="">
				</div>
			</div>
		</v-tab-item>
		<v-tab-item key="tab-core-connect">
			<div class="vm-section vm-tab-items d-flex align-center">
				<v-container>
					<v-row>
						<v-col class="col-12 col-sm-5 col-md-5 col-lg-4">
							<h2>Build a connected lifestyle</h2>
							<p class="vm-tab-content">
								Connect to the life you want, from the integration of smart devices in your home to creating special moments together as a family - whether they live close by or afar.
							</p>
							<v-btn to="/omniscript" target="_blank" rounded outlined v-if="searched">
								See available packages
							</v-btn>
							<v-btn to="/" data-section="vm-coverage-wrap" rounded outlined class="move-to" v-else>
		                      Check my coverage
		                    </v-btn>
						</v-col>
					</v-row>
				</v-container>
				<div class="vm-tab-bg" :style="{ backgroundImage: 'url(' + require('@/assets/images/products/core/tabs/core-connect.jpg') + ')' }">
					<img src="@/assets/images/products/core/tabs/core-connect.jpg" alt="">
				</div>
			</div>
		</v-tab-item>
		<v-tab-item key="tab-core-choose">
			<div class="vm-section vm-tab-items d-flex align-center">
				<v-container>
					<v-row>
						<v-col class="col-12 col-sm-5 col-md-5 col-lg-4">
							<h2 class="text-white">Secure your home</h2>
							<p class="vm-tab-content text-white">
								Safety at home is always a top priority. Connect your home security system to your fibre connection to monitor your home environment 24/7 and keep your family alert and safe.
							</p>
							<v-btn to="/omniscript" target="_blank" rounded outlined v-if="searched" class="btn-white">
								See available packages
							</v-btn>
							<v-btn to="/" data-section="vm-coverage-wrap" rounded outlined class="move-to btn-white" v-else>
		                      Check my coverage
		                    </v-btn>
						</v-col>
					</v-row>
				</v-container>
				<div class="vm-tab-bg" :style="{ backgroundImage: 'url(' + require('@/assets/images/products/core/tabs/core-save.jpg') + ')' }">
					<img src="@/assets/images/products/core/tabs/core-save.jpg" alt="">
				</div>
			</div>
		</v-tab-item>
		<v-tab-item key="tab-core-work">
			<div class="vm-section vm-tab-items d-flex align-center">
				<v-container>
					<v-row>
						<v-col class="col-12 col-sm-5 col-md-5">
							<h2 class="text-white">Move your work or business forward</h2>
							<p class="vm-tab-content text-white">
								Take one step further to creating a seamless and effective work environment from home. A fast and reliable internet connection is the solution to smart business.
							</p>
							<v-btn to="/omniscript" target="_blank" rounded outlined v-if="searched" class="btn-white">
								See available packages
							</v-btn>
							<v-btn to="/" data-section="vm-coverage-wrap" rounded outlined class="move-to btn-white" v-else>
		                      Check my coverage
		                    </v-btn>
						</v-col>
					</v-row>
				</v-container>
				<div class="vm-tab-bg" :style="{ backgroundImage: 'url(' + require('@/assets/images/products/core/tabs/core-work.jpg') + ')' }">
					<img src="@/assets/images/products/core/tabs/core-work.jpg" alt="">
				</div>
			</div>
		</v-tab-item>
		<v-tab-item key="tab-core-learn">
			<div class="vm-section vm-tab-items d-flex align-center">
				<v-container>
					<v-row>
						<v-col class="col-12 col-sm-5 col-md-5 col-lg-4">
							<h2>Acquire more knowledge</h2>
							<p class="vm-tab-content">
								With access to unlimited online educational platforms, resources and classes, put the power of learning at your children’s fingertips.
							</p>
							<v-btn to="/omniscript" target="_blank" rounded outlined v-if="searched">
								See available packages
							</v-btn>
							<v-btn to="/" data-section="vm-coverage-wrap" rounded outlined class="move-to" v-else>
		                      Check my coverage
		                    </v-btn>
						</v-col>
					</v-row>
				</v-container>
				<div class="vm-tab-bg" :style="{ backgroundImage: 'url(' + require('@/assets/images/products/core/tabs/core-learn.jpg') + ')' }">
					<img src="@/assets/images/products/core/tabs/core-learn.jpg" alt="">
				</div>
			</div>
		</v-tab-item>
    </v-tabs-items>
  </v-card>
</div>
</template>

<script>
  export default {
    data () {
      return {
        tab: null,
        searched: false,
      }
    },
    mounted(){

        $("html").css("overflow", "auto");
        $('#browse-shop-top').removeClass('d-none');

        if ($cookies.isKey("user_address")) {
            this.searched = true;
        }

        $('.move-to').on('click', function (e) {
            e.preventDefault();

            // var target = this.hash;
            var target = '#' + $(this).data('section');
            var $target = $(target);

            $('html, body').stop().animate({
                'scrollTop': $target.offset().top - 70
            }, 900, 'swing');
        });
    }
  }
</script>


<style lang="scss">
	.vm-tabs-product{
		.text-white{ color: #fff; }
		.theme--dark.v-tabs{
			&> .v-tabs-bar {
			    background-color: #EE008C;
			    height: 85px;
			}
			.v-tab{
				font-size: 25px;
				font-family: "Core Sans R 35", "Roboto", sans-serif !important;
				text-transform: none;
				/*min-width: 360px;*/
				letter-spacing: 0.1em;
				width: 20%;
				color: #fff !important;
				&.v-tab--active{
					font-family: "Core Sans R 55", "Roboto", sans-serif !important;
					&::before { opacity: 0.24 !important; }
					// &:not(:focus)::before { opacity: 0.24 !important; }
					&:after{
						content: "";
						display: block;
						width: 0; 
						height: 0; 
						border-left: 20px solid transparent;
						border-right: 20px solid transparent;
						border-top: 20px solid #EE008C;
						position: absolute;
						bottom: -20px;
						left: 50%;
					}
				}
				&:hover::before { opacity: 0.24; }
			}
			.v-tabs-slider-wrapper{ display: none; }
		}
		.vm-tab-items{
			color: #000;
			
		    .vm-tab-bg{
			    &:before{ display: none; } 
		    }	
		}

		.v-btn.btn-white{
			color: #fff;
			transition: all .2s ease-in;
			&:hover{
				background-color: #ff008e;
				border-color: #ff008e;
			}
		}
	} 
</style>