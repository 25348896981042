<template>
  <div class="cont-pages">
    <CoreBanner/>
    <CoreCoverage/>
    <ProductCoreAbout/>
    <ProductCoreTabs/>
    <FibreProcess/>
  </div>
</template>

<script>
import $ from 'jquery'
import CoreBanner from '@/components/Products/Core/Banner'
import ProductCoreAbout from '@/components/Products/Core/About'
import ProductCoreTabs from '@/components/Products/Core/Tabs'
import FibreProcess from '@/components/Products/Core/FibreProcess'
import CoreCoverage from '@/components/Products/Core/Coverage'

export default {
  name: 'ProductCore',
  components: {
    CoreBanner,
    ProductCoreAbout,
    ProductCoreTabs,
    FibreProcess,
    CoreCoverage,
  },
  mounted () {
    $('html').css('overflow', 'auto')
    $('#browse-shop-top').removeClass('d-none')
    $('.vuma-menu, .menu-overlay').removeClass('menu-show')
  },
  metaInfo () {
    return {
      title: 'Vuma Fibre Core | Super-fast Unlimited Internet To Your Home',
      meta: [
        {
          name: 'description',
          content: 'Experience super-fast fibre internet to your home with Vuma Fibre Core. Connect to unlimited fibre and change the way you and your loved ones connect.',
        },
        { property: 'og:title', content: 'Vuma Fibre Core | Super-fast Unlimited Internet To Your Home' },
        { property: 'og:site_name', content: 'Vumatel' },
        { property: 'og:type', content: 'website' },
      ],
    }
  },
}
</script>